import styled from 'styled-components';

export const StyledHeader = styled.header`
    padding: 0px 6vw 0px 3.8vw;
    position: sticky;
    top: 0px;
    right: 0px;
    left: 0px;
    z-index: 10;
    margin-bottom: 20px;
    background-color: transparent;
    transition: background-color .3s;

    @media (max-width: 991px) {
        border-bottom: 2px solid #eee;
        background-color: #fff !important;
    }
`

export const StyledHeaderInner = styled.div`
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;

    @media (max-width: 991px) {
        height: 52px;
    }
`

export const StyledHeaderLogo = styled.a`
    text-decoration: none;
    color: #666;
    font-size: 12px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;

    img {
        height: 36px;
    }

    @media (max-width: 540px) {
        img {
            height: 30px;
        }
    }
`

interface StyledHeaderMenuBarProps {
    active: boolean;
}

export const StyledHeaderMenuBar = styled.button<StyledHeaderMenuBarProps>`
    cursor: pointer;
    border: 0px none;
    mask-image: ${({ active }) => active ? 'url(/images/X.svg)' : 'url(/images/Menu.svg)'};
    mask-position: 0px 0px;
    mask-repeat: no-repeat;
    background-color: #888;
    width: 24px;
    height: 24px;
    mask-size: cover;
    display: none;
    margin-right: 0px !important;

    @media (max-width: 991px) {
        display: block;
        padding: 0;
    }
`

interface StyledHeaderMenuProps {
    show?: boolean;
}

export const StyledHeaderMenu = styled.div<StyledHeaderMenuProps>`
    display: flex;
    -webkit-box-align: center;
    align-items: center;

    @media (max-width: 991px) {
        display: ${({ show }) => show ? 'flex': 'none'};
        position: fixed;
        inset: 52px 0px 0px;
        background-color: #fff;
        z-index: 999;
        flex-direction: column;
        align-items: flex-start;
        padding: 1.375rem 0px 0.625rem 3.5rem;
    }
`

export const StyledHeaderMenuDropdown = styled.div`
    display: none;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0px);
    height: auto;
    background-color: #fff;
    padding: 0.625rem 0.375rem;
    z-index: 6;
    border: 1px solid #4a4a4a1a;
    box-shadow: rgb(224 224 224 / 20%) 0px 9px 30px;
    border-radius: 14px;

    a {
        display: block;
        text-decoration: none;
        font-weight: 700;
        font-size: 0.875rem;
        line-height: 24px;
        color: #3a3a3a;
        padding: 0.75rem;
        transition: all 0.25s ease 0s;
        border-radius: 8px;
        min-width: 260px;
    }

    a:hover {
        color: #ff5a6a;
        background-color: #6f6c6c0d;
    }

    @media (max-width: 991px) {
        position: static;
        box-shadow: none;
        border: 0px none;
        left: auto;
        transform: none;
        padding-top: 0px;
        padding-bottom: 0px;
        transition: max-height 0.3s ease 0s;
        overflow: hidden;
        display: block;
    }
`

interface StyledHeaderMenuItemProps {
    hasDropdown?: boolean;
    active?: boolean;
}

export const StyledHeaderMenuItem = styled.div<StyledHeaderMenuItemProps>`
    position: relative;
    margin-right: 48px;

    & > a, & > span {
        display: inline-flex;
        -webkit-box-align: center;
        align-items: center;
        text-decoration: none;
        font-weight: 700;
        font-size: 0.875rem;
        line-height: 24px;
        color: #3a3a3a;
        padding: 1.625rem 0px;
        position: relative;
        transition: all 0.25s ease 0s;
    }

    & > a::after, & > span::after {
        display: ${({ hasDropdown }) => hasDropdown ? 'inline-block' : 'none'};
        content: ' ';
        width: 24px;
        height: 24px;
        mask: url('/images/ChevronDown.svg') 0px 0px / cover no-repeat;
        background-color: #3a3a3a;
        margin-left: 4px;
        transform: rotate(0deg);
    }

    svg {
        stroke: #3a3a3a;
        transition: all 0.25s ease 0s;
    }

    &:hover {
        & > a, & > span {
            color: #ff5a6a;
        }

        & > a::after, & > span::after {
            background-color: #ff5a6a;
        }

        svg {
            stroke: #ff5a6a;
        }

        @media (min-width: 992px) {
            ${StyledHeaderMenuDropdown} {
                display: block;
            }
        }
    }

    @media (max-width: 991px) {
        ${StyledHeaderMenuDropdown} {
            max-height: ${({ active }) => active ? '999px' : 0};
        }

        /* & > a, & > span{
            font-size: 20px;
        } */
    }
`

interface StyledHeaderButtonProps {
    outlined?: boolean;
}

export const StyledHeaderButton = styled.a<StyledHeaderButtonProps>`
    display: inline-flex;
    text-decoration: none;
    align-items: center;
    justify-content: center;
    background-color: ${({ outlined }) => outlined ? 'transparent' : '#ffe9ee80'};
    border: ${({ outlined }) => outlined ? '1px solid #ff5a6a80' : '0 none'};
    padding: 12px 20px;
    font-size: 14px;
    border-radius: 50px;
    font-weight: bold;
    color: #ff5a6a;
    cursor: pointer;
    transition: all .3s;
    margin-right: 16px;

    &::after {
        content: ' ';
        display: inline-block;
        width: 14px;
        height: 21px;
        mask: url('/images/ChevronRight.svg') 0px 0px / cover no-repeat;
        background-color: #ff5a6a;
        opacity: 0;
        margin-right: -14px;
        transition: opacity 0.25s ease 0s;
    }

    &:last-child {
        margin-right: 0;
    }

    &:hover {
        color: ${({ outlined }) => outlined ? '#ff5a6a' : '#e54958' };
        border-color: ${({ outlined }) => outlined ? '#ff5a6a' : 'transparent' };
        padding-left: 10px;
        padding-right: 30px;

        &::after {
            opacity: 1;
        }
    }

    @media (max-width: 991px) {
        font-size: 20px;
        margin: 8px 0;
    }
`

export const PageWrapper = styled.div`
    /* display: flex;
    flex-direction: column;
    height: 100%; */
    /* background: url('/images/bg.jpg') center center no-repeat;
    background-size: cover; */
`

export const PageContainer = styled.div`
    /* flex: 1; */
`