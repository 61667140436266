import styled from "styled-components";

export const FooterWrapper = styled.footer`
  background-color: rgb(248, 249, 250);
`;

export const FooterContainer = styled.div`
  margin: 0 auto;
  max-width: 1440px;
  padding: 1rem;

  @media (min-width: 1024px) {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  @media (min-width: 640px) {
    padding-top: 4rem;
    padding-bottom: 42px;
  }
`;

export const FooterInner = styled.div`
  @media (min-width: 1024px) {
    padding: 2rem;
  }
`;

export const FooterMain = styled.div`
  display: flex;
  row-gap: 1.5rem;
  flex-direction: column;

  .FooterGcIcon {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 34px;
    margin-bottom: 35px;
  }

  @media (min-width: 1024px) {
    justify-content: space-between;
    flex-direction: row;
  }
`;

export const FooterBottom = styled.div`
  margin-top: 1.5rem;

  @media (min-width: 640px) {
    margin-top: 42px;
  }
`;

export const FooterCopyright = styled.p`
  margin: 1.5rem 0;
`;

export const FooterNav = styled.div`
  display: flex;
  flex-basis: 326px;
  column-gap: 75px;

  & > div {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.32px;
  }

  h3 {
    font-weight: 800;
    margin-bottom: 1rem;
    font-size: 16px;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    margin-top: 16px;
  }

  li:first-child {
    margin-top: 0;
  }

  li a {
    display: inline-block;
    font-weight: 600;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    text-decoration: none;
    color: rgb(95, 99, 104);
  }

  li a:hover {
    color: rgb(32, 33, 36);
  }

  @media (min-width: 1024px) {
    flex-basis: 396px;
  }

  @media (min-width: 640px) {
    column-gap: 129px;
  }
`;

export const FooterInfo = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  row-gap: 1.5rem;

  @media (min-width: 1024px) {
    flex-direction: row;
    align-items: center;
  }
`;

export const FooterPuct = styled.span`
  order: 9999;
  width: fit-content;
  border-radius: 0.5rem;
  background-color: rgba(95, 99, 104, 0.1);
  padding: 0.375rem 0.75rem;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.32px;

  @media (min-width: 1024px) {
    order: -9999;
  }
`;

export const FooterContactInfo = styled.div`
  display: flex;
  column-gap: 57px;
  row-gap: 1.5rem;
  flex-direction: column;

  a {
    display: inline-flex;
    align-items: center;
    column-gap: 1rem;
    text-decoration: none;
    color: rgb(32, 33, 36);
  }

  a span {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.32px;
    font-weight: 600;
  }

  @media (min-width: 1024px) {
    align-items: center;
  }

  @media (min-width: 640px) {
    flex-direction: row;
  }
`;

export const FooterLinks = styled.div`
  display: flex;
  margin-top: 54px;
  align-items: flex-start;
  row-gap: 1.5rem;
  border-top: 1px solid rgba(95, 99, 104, 0.1);
  padding-top: 1.5rem;
  flex-direction: column;

  @media (min-width: 1024px) {
    flex-direction: row;
    justify-content: space-between;
  }

  & > div:first-child {
    display: inline-flex;
    align-items: flex-end;
    column-gap: 0.875rem;

    a {
      border-radius: 50%;
      background-color: #fff;
      padding: 0.75rem;
      font-size: 0;
    }

    .sr-only {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      white-space: nowrap;
      border-width: 0;
    }
  }

  & > div:last-child {
    display: inline-flex;
    column-gap: 30px;
    color: rgb(95, 99, 104);
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.36px;

    a {
      color: inherit;
    }
  }
`;
