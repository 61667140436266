// export const IS_DEV = process.env.REACT_APP_IS_DEV && process.env.REACT_APP_IS_DEV === '1'

export const IS_DEV = false;

export const PROVIDER_ID = '269';

export const API_URL = IS_DEV
    ? 'https://dev.powerlego.com/ApiGateway/'
    : 'https://www.powerlego.com/ApiGateway/'
export const ACC_KEY = IS_DEV
    ? '359c78e8a945a841047fc3bc16c0b446'
    : '5edfb8859ed4cb5fb935adfa528daabd'

export const GC_URL = IS_DEV ? "https://gcdev-signup.goodcharlie.com/signup/api/v1/" : "https://api.goodcharlie.com/";

export const iCheckConfig = {
    ccUrl: 'https://iframe.icheckgateway.com/iFrameCC.aspx',
    baUrl: 'https://iframe.icheckgateway.com/iFrameBA.aspx',
    appId: IS_DEV ? '123456' : 'JWkmIBGu9DPLMXfQcykVIfS29AltFFFz',
    appSecret: IS_DEV ? '123456' : 'xsUBCc6pBYOuSFuyGM8F22ob7F7LAmxh',
    // appSiteId: 'NBPP',
    styles: 'payment{background:white;border-color:rgb(221,221,221);}input[type="submit"],input[type="reset"]{background:rgb(255,91,106);border-radius:30px;border:0 none;}'
}

export const dunsUtilityList = [
    { value: '007923311', label: 'AEP North', id: '4', code: 'AEPWTU' },
    { value: '007924772', label: 'AEP Central', id: '6', code: 'AEPCPL' },
    { value: '007929441', label: 'Texas New Mexico Power (TNMP)', id: '5', code: 'TNMP' },
    { value: '1039940674000', label: 'Oncor Electric Delivery', id: '2', code: 'Oncor' },
    { value: '957877905', label: 'CenterPoint Energy', id: '1', code: 'CPE' },
    // { value: '0088288574800', label: 'Nueces Coop' },
    // { value: '827438383', label: 'Entergy Gulf States Inc' },
]