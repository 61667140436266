import React, { useEffect, useState } from 'react'
import './App.css'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
// import { IS_DEV } from './config'
// import { Enrollment } from './enrollment'
import Footer from './components/Footer_new'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { ThemeProvider } from 'styled-components'
import 'react-datetime/css/react-datetime.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faFilePdf,
    faTimesCircle,
    faSearch,
    faCheckCircle,
    faCheckSquare,
    faCircle,
    faSquare,
    faBars,
    faArrowDown,
    faTimes,
    faSlidersH,
    faCaretUp,
    faCaretDown,
    faExchangeAlt,
} from '@fortawesome/free-solid-svg-icons'
import './i18n'
import Header from './components/header'
import { PageContainer, PageWrapper } from './components/styles/Header.styled'
import Product from './product'
import { getURLParam, getUtmParameter } from './common'
import { fetchGlobalSettings } from './api/api'
import SGMaintenaceModal from './components/SGMaintenaceModal'

library.add(
    faFilePdf as any,
    faTimesCircle as any,
    faSearch as any,
    faCheckCircle as any,
    faCheckSquare as any,
    faCircle as any,
    faSquare as any,
    faBars as any,
    faArrowDown as any,
    faTimes as any,
    faSlidersH as any,
    faCaretUp as any,
    faCaretDown as any,
    faExchangeAlt as any
)

const theme = {
    colors: {
        primary: '#ff5a6a',
        secondary: '#0bcebb'
    },
}

function App() {
	const [ gcPhone, setGcPhone ] = useState('');
	const [ utms, setUtms ] = useState('');
    // const [ ip, setIp ] = useState('');
    const [ zipcode, setZipcode ] = useState('');

    useEffect(() => {
        // getIp((res: any) => setIp(res));

		const utmsParameter = getUtmParameter();
		setUtms(utmsParameter);

        function changeHeaderBgColor() {
            var header = document.getElementsByTagName('header');
            var scrollPosition = window.scrollY;
          
            if (scrollPosition > 0) {
              header[0].style.backgroundColor = '#fff';
            } else {
              header[0].style.backgroundColor = 'transparent';
            }
        }
        window.addEventListener('scroll', changeHeaderBgColor);

        return () => {
            window.removeEventListener('scroll', changeHeaderBgColor);
        }
    }, [])

    useEffect(() => {
        const utmContent = getURLParam('utm_content');
        fetchGlobalSettings(utmContent).then(res => {
            if (res && res.response) {
                const { phone_number, zip_code } = res.response;
                if (phone_number) {
                    setGcPhone(phone_number);
                }
                if (zip_code) {
                    setZipcode(zip_code);
                }
            }
        })
    }, [])

    // const getGlobalSettings = () => {
		// const { search } = window.location || {};
        
		// if (search) {
			// const utmContent = getURLParam('utm_content');
			// if (utmContent) {
				// fetchGlobalSettings(utmContent, ip).then(res => {
				    // if (res && res.response && res.response.response && res.response.response.phone_number) {
				        // setGcPhone(res.response.response.phone_number);
				    // }
				// })
			// }
		// }
    // }

    // const getIp = (callback: any) => {
    //     var request = new XMLHttpRequest();
    //     request.open('GET', 'https://api.ipify.org/?format=json', true);
    //     request.onload = function() {
    //         if (request.status >= 200 && request.status < 400) {
    //         var data = JSON.parse(request.responseText);
    //             // callback(data.ip);
    //             callback('70.234.208.65'); // testing
    //         } else {
    //             callback(null);
    //         }
    //     };
    //     request.onerror = function() {
    //         callback(null);
    //     };
    //     request.send();
    // }

    return (
        <ThemeProvider theme={theme}>
            <PageWrapper>
                <PageContainer>
                    <Header gcPhone={gcPhone} />
                    <SGMaintenaceModal/>
                    <BrowserRouter
                        // basename={`${IS_DEV ? '/dev' : '/'}`}
                    >
                        <Routes>
                            <Route path="/" element={<Product defaultZipcode={zipcode} />} />
                        </Routes>
                    </BrowserRouter>
                </PageContainer>
                <Footer gcPhone={gcPhone} />
            </PageWrapper>
            <ToastContainer />
        </ThemeProvider>
    )
}

export default App
