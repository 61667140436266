import { Button } from "./styles/Button.styled";
import { Tooltip } from 'react-tooltip'
import ReactModal from "react-modal";
import { ModalBody, ModalClose, PlanDocLink, RenewalFeaturesRow, RenewalFreePackLabel, RenewalPlanDetailBottom, RenewalPlanDetailContent, RenewalPlanDetailDocs, RenewalPlanDetailFacts, RenewalPlanDetailInfo, RenewalPlanDetailPriceTable, RenewalPlanDetailPriceTbody, RenewalPlanDetailPriceThead, RenewalPlanEbillLabel, RenewalPlanInfo, RenewalPlanLabel, RenewalPlanNameRow, RenewalPlanPrice, RenewalPlanRow } from "./styles/Product.styled";
import NumberFormat from "react-number-format";
import 'react-tooltip/dist/react-tooltip.css';
import { labelColors } from "../common";

export const ProductDetailModal = ({ show, data, handleGoEnroll, handleClose }: {
    show: boolean;
    data: any;
    handleGoEnroll: Function;
    handleClose: Function;
}) => {
    const content = () => {
        if (!data) return null;
        const { name, kwh0500Price, kwh1000Price, kwh2000Price, productDocuments, contractLengthName, contractPeriodName, earlyTerminationFee, isEbillRequired, productDisplayShortDescription, productScript, productDisplayFeature5 } = data;

        const rateType = productDisplayShortDescription.split(',')[1];
        
        const lowerCaseFeature5: string = productDisplayFeature5.toLowerCase();

        return (
            <RenewalPlanDetailContent>
                <RenewalPlanDetailInfo>
                    <RenewalPlanDetailPriceTable>
                        <RenewalPlanDetailPriceThead>
                            <span>Average Monthly usage</span>
                            <span>Price per kWh</span>
                        </RenewalPlanDetailPriceThead>
                        <RenewalPlanDetailPriceTbody>
                            <div>
                                <span>500 kWh</span>
                                <NumberFormat displayType={'text'} value={kwh0500Price} decimalScale={1} fixedDecimalScale={true} suffix="¢" />
                            </div>
                            <div>
                                <span>1,000 kWh</span>
                                <NumberFormat displayType={'text'} value={kwh1000Price} decimalScale={1} fixedDecimalScale={true} suffix="¢" />
                            </div>
                            <div>
                                <span>2,000 kWh</span>
                                <NumberFormat displayType={'text'} value={kwh2000Price} decimalScale={1} fixedDecimalScale={true} suffix="¢" />
                            </div>
                        </RenewalPlanDetailPriceTbody>
                    </RenewalPlanDetailPriceTable>
                    <h4>Plan Documents</h4>
                    <RenewalPlanDetailDocs>
                        {
                            productDocuments.map((docVal: any, docIdx: number) => (
                                <PlanDocLink
                                    href={docVal.documentURL}
                                    target="_blank"
                                    key={docIdx}
                                >
                                    {docVal.documentName}
                                </PlanDocLink>
                            ))
                        }
                    </RenewalPlanDetailDocs>
                </RenewalPlanDetailInfo>
                <RenewalPlanDetailFacts>
                    <RenewalPlanNameRow>
                        <h4>{name}</h4>
                        {
                            productDisplayFeature5 &&
                            <RenewalPlanLabel type={
                                (labelColors as any)[lowerCaseFeature5] ? (labelColors as any)[lowerCaseFeature5] : 'red'
                            }>{productDisplayFeature5}</RenewalPlanLabel>
                        }
                    </RenewalPlanNameRow>
                    <RenewalPlanPrice>
                        <div>
                            <b><NumberFormat displayType={'text'} value={kwh2000Price} decimalScale={1} fixedDecimalScale={true} /></b>
                            <i>¢</i>
                        </div>
                        <span>at 2,000 kWh usage</span>
                    </RenewalPlanPrice>
                    {
                        // isEbillRequired &&
                        <RenewalPlanEbillLabel><img src="/images/alert-circle.png" width="16" alt="" />Requires electronic billing.</RenewalPlanEbillLabel>
                    }
                    <RenewalPlanInfo>
                        <RenewalPlanRow>
                            <label><img src="/images/icon-calendar-heart.png" width="35" alt="" />Plan length:</label>
                            <span>{`${contractPeriodName === 'Years' ? parseInt(contractLengthName) * 12 : contractLengthName} months`}</span>
                        </RenewalPlanRow>
                        {
                            rateType &&
                            <RenewalPlanRow>
                                <label><img src="/images/icon-lock.png" width="35" alt="" />Plan type:</label>
                                <span className="upper1Letter">{rateType}</span>
                            </RenewalPlanRow>
                        }
                        <RenewalPlanRow>
                            <label><img src="/images/icon-credit-card-x.png" width="35" alt="" />Cancel fee:</label>
                            <span>
                                $20 / month
                                <i data-tooltip-id="cancel-fee-tip" data-tooltip-content="$20 per month remaining on the contract term" className="renewal-tooltip-icon" />
                                <Tooltip id="cancel-fee-tip" className="renewal-tooltip-theme" place="left" />
                            </span>
                        </RenewalPlanRow>
                    </RenewalPlanInfo>
                    <RenewalFreePackLabel><img src="/images/icon-dog-jump.png" width="18" alt="" />Free Pet Perks with every plan</RenewalFreePackLabel>
                    <div style={{marginTop:20,marginBottom:20}}>
                        <Button onClick={() => {
                            handleGoEnroll(data);
                            handleClose();
                        }}>Select Plan</Button>
                    </div>
                </RenewalPlanDetailFacts>
            </RenewalPlanDetailContent>
        )
    }

    return (
        <ReactModal
            isOpen={show}
            closeTimeoutMS={110}
            onRequestClose={() => handleClose()}
            shouldCloseOnOverlayClick={true}
            style={{content: {maxWidth:900}}}
        >
            <ModalBody>
                { content() }
                <RenewalPlanDetailBottom>
                    <RenewalFeaturesRow>
                        <div>
                            <i><img src="/images/icon-tag.png" alt="" width="36" /></i>
                            <h4>Straightforward pricing</h4>
                            <p>All our plans have straightforward pricing with no gimmicks or hidden fees.</p>
                        </div>
                        <div>
                            <i><img src="/images/icon-piggy-bank.png" alt="" width="36" /></i>
                            <h4>Tools to help you save</h4>
                            <p>Save money with our customized energy usage and bill alerts.</p>
                        </div>
                    </RenewalFeaturesRow>
                    <RenewalFeaturesRow>
                        <div>
                            <i><img src="/images/icon-dog-jump.png" alt="" width="36" /></i>
                            <h4>Free benefits</h4>
                            <p>Every plan comes with our free Pet Perks, including a $750 emergency fund.</p>
                        </div>
                        <div>
                            <i><img src="/images/icon-hearts.png" alt="" width="36" /></i>
                            <h4>Community impact</h4>
                            <p>With every bill we donate money to help homeless animals throughout Texas.</p>
                        </div>
                    </RenewalFeaturesRow>
                </RenewalPlanDetailBottom>
            </ModalBody>
            <ModalClose onClick={() => handleClose()} />
        </ReactModal>
    )
}