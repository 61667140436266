import { useEffect, useRef, useState } from "react";
import { Dropdown, Form } from "react-bootstrap";
import NumberFormat from "react-number-format";
import { Tooltip } from 'react-tooltip'
import { LoadingRow } from "./components/loading-row";
// import { ReviewSection } from "./components/ReviewSection"
import { Button } from "./components/styles/Button.styled";
import {
    CommunityItem,
    CommunityItemContent,
    CommunityItemContentFooter,
    CommunityItemContentHeader,
    CommunityRow,
    FaqList, FaqListItem, FaqListItemAnswer, FaqListItemInner, FaqListItemQuestion, FaqSection, PlanError, PlanTabGroup, PlanTabGroupRow, RenewalFeaturesRow, RenewalFeaturesSection, RenewalPlanAction, RenewalPlanInfo, RenewalPlanItem, RenewalPlanItemInner, RenewalPlanLabel, RenewalPlanList, RenewalPlanName, RenewalPlanPrice, RenewalPlanRow, SectionHead, SwiperBtn, ZipcodeRow, ZipcodeSection, NewFeatureSection, NewFeatureSectionInner, MiddleSection, ZipcodeCol,
} from "./components/styles/Product.styled"
import { ProductDetailModal } from './components/ProductDetailModal';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import 'swiper/css';
import { fetchFaqs, fetchPlans } from "./api/api";
import qs from 'qs'
import { useLocation } from 'react-router-dom'
import { GoogleReviewRating, PucReviewRating } from "./components/GoogleReviewRating";
import 'react-tooltip/dist/react-tooltip.css';
import { labelColors } from "./common";
import { ReviewRatingSection } from "./components/styles/ReviewRating.styled";

const SLIDESPERVIEW_XS = 1;
const SLIDESPERVIEW_MD = 2;
const SLIDESPERVIEW_LG = 3;

const Product = ({ defaultZipcode }: {
    defaultZipcode: string;
}) => {
    const location = useLocation()
    const [ zipcodeVal, setZipcodeVal ] = useState('');
    const [ loading, setLoading ] = useState(false);
    const [ planType, setPlanType ] = useState('popular'); // popular, green, all
    const [ planList, setPlanList ] = useState<any>([]);
    const [ planDetail, setPlanDetail ] = useState(undefined);
    const [ showPlanDetail, setShowPlanDetail ] = useState(false);
    const [ faqs, setFaqs ] = useState([]);
    const [ activeFaqs, setActiveFaqs ] = useState<number[]>([]);
    const [ error, setError ] = useState('');
    const [ isBeginSlide, setIsBeginSlide ] = useState(false);
    const [ isEndSlide, setIsEndSlide ] = useState(false);
    const [ showArrows, setShowArrows ] = useState(true);
    const [ showPagination, setShowPagination ] = useState(true);
    const [ selectedUtilityIdx, setSelectedUtilityIdx ] = useState<number|undefined>(undefined);
    const [ isMobile, setIsMobile ] = useState(false);
    const { utm_content, utm_campaign, utm_source, utm_medium, utm_term, zipcode, _aiid } = qs.parse(
        location.search, {
            ignoreQueryPrefix: true,
        })
    const sliderRef = useRef<any>(null);

    useEffect(() => {
        // console.log(zipcode, defaultZipcode)
        if (zipcode) {
            setZipcodeVal(`${zipcode}`);
            handleSearchPlan(`${zipcode}`);
        }
        else {
            setZipcodeVal(defaultZipcode);
            handleSearchPlan(defaultZipcode);
        }
    }, [zipcode, defaultZipcode])

    useEffect(() => {
        
        //@ts-ignore
        window.dataLayer = window.dataLayer || [];

        console.log('Data in document.referrer ==>', document.referrer);

        if(document.referrer === "https://goodcharlierx.com/" || document.referrer === 'https://user.petcareclub.vet/'){
            //@ts-ignore
            window.dataLayer.push({
                'event': 'petcare_club_funnel',
                'funnel_type': 'petcare', // fixed value
                'funnel_step': '6C', // fixed value
                'funnel_name': 'to plan page' // fixed value
            });
        }
        
        //@ts-ignore
        window.dataLayer.push({
            'event': 'sales_funnel',
            'funnel_type': 'plan_page', // fixed value
            'funnel_step': '0', // fixed value
            'funnel_name': 'selected plan' // fixed value
        });

        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        setIsMobile(isMobile);

        fetchFaqs().then(res => {
            if (res && res.picklist && res.picklist[0] && res.picklist[0].picklist_entries) {
                setFaqs(res.picklist[0].picklist_entries);
            }
        })
    }, [])

    const handleSearchPlan = (zip?: string) => {
        const _zipcode = zip || zipcodeVal;

        if(_zipcode){
            //datalayer script added JIRA 990
            //@ts-ignore
            window.dataLayer = window.dataLayer || [];
            //@ts-ignore
            window.dataLayer.push({
            'event': 'zipcode_update',
            'zipcode': _zipcode // dynamic value. e.g. '77005'
            });
        }

        if (_zipcode.length === 5 && !loading) {
            setLoading(true);
            setPlanList([]);
            setSelectedUtilityIdx(undefined);
            setError('');
            fetchPlans(_zipcode, utm_content, utm_campaign, utm_source, utm_medium, utm_term).then(res => {
                setLoading(false);
                if (res && res.response && res.status !== 0) {
                    setPlanList(res.response);

                    if (res.response.length === 1) {
                        setSelectedUtilityIdx(0);
                    }
                }
                else {
                    setError('Uh-oh! GoodCharlie hasn’t made it to your area just yet.');
                }
            })
        }
    }

    useEffect(() => {
        if (sliderRef.current) {
            // console.log(sliderRef.current)
            sliderRef.current.swiper.slideTo(0);
            handleResize(sliderRef.current.swiper);
        }
    }, [planType])

    const redirectToEnrollment = (val: any) => {
        if (!val || selectedUtilityIdx === undefined) return;
        const { productID } = val;
        const utilityCode = planList[selectedUtilityIdx].distributionServiceProvider.utility_code;
        let enrollmentUrl = `https://enrollment.goodcharlie.com/?utility_code=${utilityCode}&product_id=${productID}`;
        if (utm_content) {
            enrollmentUrl += `&utm_content=${utm_content}`;
        }
        if (utm_campaign) {
            enrollmentUrl += `&utm_campaign=${utm_campaign}`;
        }
        if (utm_source) {
            enrollmentUrl += `&utm_source=${utm_source}`;
        }
        if (utm_medium) {
            enrollmentUrl += `&utm_medium=${utm_medium}`;
        }
        if (utm_term) {
            enrollmentUrl += `&utm_term=${utm_term}`;
        }
        if (_aiid) {
            enrollmentUrl += `&_aiid=${_aiid}`;
        }

        // datalayer script added JIRA 990
        //@ts-ignore
        window.dataLayer = window.dataLayer || [];
        //@ts-ignore
        window.dataLayer.push({
            'event': 'plan_select',
            'zipcode': zipcodeVal, // dynamic value. e.g. '77001',
            'prod_name': val?.name // dynamic value. e.g. 'GoodEnergy 12'
        })
        window.location.href = enrollmentUrl;
    }

    const handleResize = (swiper: any) => {
        const { slides, isBeginning, isEnd } = swiper;
        const numSlides = slides.length;
        const width = window.innerWidth;
        
        if (width < 768) {
            // console.log(`xs: ${numSlides > SLIDESPERVIEW_XS}`)
            setShowPagination(numSlides > SLIDESPERVIEW_XS);
            setShowArrows(numSlides > SLIDESPERVIEW_XS);
        }
        else if (width >= 768 && width < 992) {
            // console.log(`md: ${numSlides > SLIDESPERVIEW_MD}`)
            setShowPagination(numSlides > SLIDESPERVIEW_MD);
            setShowArrows(numSlides > SLIDESPERVIEW_MD);
        }
        else {
            // console.log(`lg: ${numSlides > SLIDESPERVIEW_LG}`)
            setShowPagination(numSlides > SLIDESPERVIEW_LG);
            setShowArrows(numSlides > SLIDESPERVIEW_LG);
        }
        setIsBeginSlide(isBeginning);
        setIsEndSlide(isEnd);
    }

    const handlePaging = (swiper: any) => {
        const { activeIndex, slides } = swiper;
        const numSlides = slides.length;
        const width = window.innerWidth;
        
        let pages = 0;
        if (width < 768) {
            pages = numSlides - SLIDESPERVIEW_XS;
        }
        else if (width >= 768 && width < 992) {
            pages = numSlides - SLIDESPERVIEW_MD;
        }
        else {
            pages = numSlides - SLIDESPERVIEW_LG;
        }
        
        setIsBeginSlide(activeIndex < 1);
        setIsEndSlide(pages === activeIndex);
    }

    const displayPlanList = () => {
        if (selectedUtilityIdx === undefined) return null;
        if (!planList[selectedUtilityIdx]) return null;
        
        let [ ...list ] = planList[selectedUtilityIdx].webProducts;
        if (planType === 'popular') {
            list = planList[selectedUtilityIdx].webProducts.filter((item: any) => item.isFeatured === true);
        }
        if (planType === 'green') {
            list = planList[selectedUtilityIdx].webProducts.filter((item: any) => item.greenEnergyPercent === 1);
        }

        const firstPopularProduct = list.find((item: any) => item.isFeatured === true);
        return (
            <>
                <Swiper
                    spaceBetween={20}
                    slidesPerView={SLIDESPERVIEW_XS}
                    pagination={showPagination}
                    modules={[Pagination]}
                    centerInsufficientSlides={true}
                    onResize={handleResize}
                    breakpoints={{
                        768: {
                            slidesPerView: SLIDESPERVIEW_MD
                        },
                        992: {
                            slidesPerView: SLIDESPERVIEW_LG
                        }
                    }}
                    onSlidePrevTransitionEnd={(swiper) => handlePaging(swiper)}
                    onSlideNextTransitionEnd={(swiper) => handlePaging(swiper)}
                    ref={sliderRef}
                >
                {
                    list.map((val: any, idx: number) => {
                        const { name, productID, isRecommended, kwh2000Price, contractLengthName, contractPeriodName, productDisplayFeature5, productDisplayShortDescription } = val;
            
                        const rateType = productDisplayShortDescription.split(',')[1];
                
                        let showArrow = productDisplayFeature5.toLowerCase().indexOf('popular') >= 0 && (firstPopularProduct && firstPopularProduct.productID === val.productID);

                        const tooltipId = `${productID}-${selectedUtilityIdx}-${idx}`;

                        const lowerCaseFeature5: string = productDisplayFeature5.toLowerCase();
                        
                        return (
                            <SwiperSlide key={idx}>
                                <RenewalPlanItem recommended={isRecommended}>
                                    <RenewalPlanItemInner isPopular={showArrow}>
                                        <RenewalPlanName>
                                            <b>{name}</b>
                                            {
                                                productDisplayFeature5 &&
                                                <RenewalPlanLabel type={
                                                    (labelColors as any)[lowerCaseFeature5] ? (labelColors as any)[lowerCaseFeature5] : 'red'
                                                }>{productDisplayFeature5}</RenewalPlanLabel>
                                            }
                                        </RenewalPlanName>
                                        <RenewalPlanPrice>
                                            <div>
                                                <b><NumberFormat displayType={'text'} value={kwh2000Price} decimalScale={1} fixedDecimalScale={true} /></b>
                                                <i>¢</i>
                                            </div>
                                            <span>at 2,000 kWh</span>
                                        </RenewalPlanPrice>
                                        <RenewalPlanAction>
                                            <Button secondary onClick={() => {
                                                //@ts-ignore
                                                window.dataLayer = window.dataLayer || [];
                                                //@ts-ignore
                                                window.dataLayer.push({
                                                    'event': 'plan_details',
                                                    'zipcode': zipcodeVal, // dynamic value. e.g. '77001',
                                                    'prod_name': val.name // dynamic value. e.g. 'GoodEnergy 12'
                                                });
                                                setPlanDetail(val);
                                                setShowPlanDetail(true);
                                            }}>Details</Button>
                                            <Button onClick={() => {
                                                redirectToEnrollment(val)
                                            }}>Select</Button>
                                        </RenewalPlanAction>
                                        <RenewalPlanInfo>
                                            <RenewalPlanRow>
                                                <label>Plan length:</label>
                                                <span>{`${contractPeriodName === 'Years' ? parseInt(contractLengthName) * 12 : contractLengthName} months`}</span>
                                            </RenewalPlanRow>
                                            {
                                                rateType &&
                                                <RenewalPlanRow>
                                                    <label>Plan type:</label>
                                                    <span className="upper1Letter">{rateType}</span>
                                                </RenewalPlanRow>
                                            }
                                            <RenewalPlanRow>
                                                <label>Cancel fee:</label>
                                                <span>
                                                    $20 / month
                                                    <i data-tooltip-content="$20 per month remaining on the contract term" data-tooltip-id={tooltipId}className="renewal-tooltip-icon" />
                                                    <Tooltip id={tooltipId} openOnClick={isMobile} className="renewal-tooltip-theme" place="left" />
                                                </span>
                                            </RenewalPlanRow>
                                        </RenewalPlanInfo>
                                    </RenewalPlanItemInner>
                                </RenewalPlanItem>
                            </SwiperSlide>
                        )
                    })
                }
                </Swiper>
                {
                    (showArrows && !isBeginSlide) &&
                    <SwiperBtn various="prev" onClick={() => {
                        if (!sliderRef.current) return;
                        const { swiper } = sliderRef.current;
                        swiper.slidePrev();
                        handlePaging(swiper)
                    }}><i /></SwiperBtn>
                }
                {
                    (showArrows && !isEndSlide) &&
                    <SwiperBtn various="next" onClick={() => {
                        if (!sliderRef.current) return;
                        const { swiper } = sliderRef.current;
                        swiper.slideNext();
                        handlePaging(swiper)
                    }}><i /></SwiperBtn>
                }
            </>
        )
    }

    const displayUtilityDropdown = () => {
        if (planList.length <= 1) return null;
        return (
            <div id="utility-dropdown" className="d-flex align-items-center justify-content-center">
                <Dropdown>
                    <Dropdown.Toggle
                        disabled={loading}
                        variant="outline-secondary"
                    >
                        {selectedUtilityIdx !== undefined ? planList[selectedUtilityIdx].distributionServiceProvider.name : 'Select Utility'}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {(planList as Object[]).map(
                            (option: any, optionIdx: number) => {
                                return (
                                    <Dropdown.Item
                                        eventKey={option.distributionServiceProvider.name}
                                        key={optionIdx}
                                        onClick={() => {
                                            setSelectedUtilityIdx(optionIdx);
                                        }}
                                        active={
                                            (selectedUtilityIdx === optionIdx) ? true : false
                                        }>
                                        {option.distributionServiceProvider.name}
                                    </Dropdown.Item>
                                )
                            }
                        )}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        )
    }

    return (
        <div id="homepage">
            <div className="first-section">
                <div className="container">
                    <div className="main-section">
                        <ZipcodeSection>
                            <h1>Plans made simple. Savings made easy.</h1>
                            {/* <p>Viewing electricity plans we offer in</p> */}
                            <ZipcodeRow>
                                <ZipcodeCol>
                                    <Form.Group>
                                        <NumberFormat
                                            name="zipcode"
                                            value={zipcodeVal}
                                            maxLength={5}
                                            onChange={(evt: any) => setZipcodeVal(evt.target.value)}
                                            className="form-control"
                                            placeholder="Enter Zip Code"
                                            disabled={loading}
                                        />
                                    </Form.Group>
                                    { displayUtilityDropdown() }
                                </ZipcodeCol>
                                <Button onClick={() => handleSearchPlan()} disabled={zipcodeVal.length < 5}>Update Plans</Button>
                            </ZipcodeRow>
                        </ZipcodeSection>
                        <ReviewRatingSection>
                            {/* <PucReviewRating/> */}
                            <GoogleReviewRating />
                        </ReviewRatingSection>
                        
                        {
                            (planList.length > 0 && selectedUtilityIdx !== undefined) &&
                            <PlanTabGroupRow>
                                <PlanTabGroup>
                                    <span className={planType === 'popular' ? "active" : ''} onClick={() => setPlanType('popular')}>Popular</span>
                                    <span className={planType === 'green' ? "active" : ''} onClick={() => setPlanType('green')}>100% Green</span>
                                    <span className={planType === 'all' ? "active" : ''} onClick={() => setPlanType('all')}>All Plans</span>
                                </PlanTabGroup>
                            </PlanTabGroupRow>
                        }
                        {
                            loading && <div style={{paddingBottom:100}}><LoadingRow /></div>
                        }
                        {
                            error &&
                            <PlanError>{error}</PlanError>
                        }
                        {
                            !loading && planList.length > 0 &&
                            <RenewalPlanList>
                                { displayPlanList() }
                            </RenewalPlanList>
                        }
                    </div>
                </div>
            </div>
            <MiddleSection>
                <div className="container">
                    <NewFeatureSection>
                        <div className="container">
                            <h2 className="section_title">Why Texans are switching to GoodCharlie</h2>
                                <NewFeatureSectionInner>
                                    <div>
                                        <i><img src="/images/icon-dog-jump.png" alt="" width="36" /></i>
                                        <h4>Free benefits</h4>
                                        <p>Every plan comes with our free Pet Perks, including a $750 emergency fund.</p>
                                    </div>
                                    <div>
                                        <i><img src="/images/icon-tag.png" alt="" width="36" /></i>
                                        <h4>Straightforward pricing</h4>
                                        <p>All our plans have straightforward pricing with no gimmicks or hidden fees.</p>
                                    </div>
                                    <div>
                                        <i><img src="/images/icon-hearts.png" alt="" width="36" /></i>
                                        <h4>Community impact</h4>
                                        <p>With every bill we donate money to help homeless animals throughout Texas.</p>
                                    </div>
                                    <div>
                                        <i><img src="/images/icon-piggy-bank.png" alt="" width="36" /></i>
                                        <h4>Tools to help you save</h4>
                                        <p>Save money with our customized energy usage and bill alerts.</p>
                                    </div>
                                </NewFeatureSectionInner>
                        </div>
                    </NewFeatureSection>
                </div>
            </MiddleSection>
            <div>
                <div className="container">
                    <h2 className="section_title">Trusted by top names in the community</h2>
                    <CommunityRow>
                        <Swiper
                            spaceBetween={20}
                            slidesPerView={1}
                            pagination={{ clickable: true }}
                            modules={[Pagination]}
                            centerInsufficientSlides={true}
                            breakpoints={{
                                768: {
                                    slidesPerView: 2
                                },
                                992: {
                                    slidesPerView: 3
                                }
                            }}
                            ref={sliderRef}
                        >
                            <SwiperSlide>
                                <CommunityItem style={{backgroundImage:'url("/images/community/khbubbles.png")'}}>
                                    <CommunityItemContent>
                                        <CommunityItemContentHeader>
                                            <img src="/images/community/khbubbles_img.png" width="24" height="24" alt="" />
                                            <span>khbubbles</span>
                                            <img src="/images/community/verified_tick.png" width="16" height="16" alt="" />
                                        </CommunityItemContentHeader>
                                        <p>I love that GoodCharlie supports the animals and rescue organizations of Texas.</p>
                                        <CommunityItemContentFooter>
                                            <h6>Kilyn Horton</h6>
                                            <p>Animal Advocate, @khbubbles</p>
                                        </CommunityItemContentFooter>
                                    </CommunityItemContent>
                                </CommunityItem>
                            </SwiperSlide>
                            <SwiperSlide>
                                <CommunityItem style={{backgroundImage:'url("/images/community/julia.png")'}}>
                                    <CommunityItemContent>
                                        <CommunityItemContentHeader>
                                            <img src="/images/community/julia_img.png" width="24" height="24" alt="" />
                                            <span>juliamoralesclark</span>
                                            <img src="/images/community/verified_tick.png" width="16" height="16" alt="" />
                                        </CommunityItemContentHeader>
                                        <p>I’m thrilled that GoodCharlie automatically supports my favorite rescue, Houston Pets Alive!</p>
                                        <CommunityItemContentFooter>
                                            <h6>Julia Morales Clark</h6>
                                            <p>Houston Astros Sports Reporter, AT&T Sports Net</p>
                                        </CommunityItemContentFooter>
                                    </CommunityItemContent>
                                </CommunityItem>
                            </SwiperSlide>
                            <SwiperSlide>
                                <CommunityItem style={{backgroundImage:'url("/images/community/dallaspets.png")'}}>
                                    <CommunityItemContent>
                                        <CommunityItemContentHeader>
                                            <img src="/images/community/dallaspets_img.png" width="24" height="24" alt="" />
                                            <span>dallaspetsalive</span>
                                            <img src="/images/community/verified_tick.png" width="16" height="16" alt="" />
                                        </CommunityItemContentHeader>
                                        <p>GoodCharlie’s commitment to animals in need allows us to save the most vulnerable pets in North Texas.</p>
                                        <CommunityItemContentFooter>
                                            <h6>Leslie Sans</h6>
                                            <p>Executive Director, Dallas Pets Alive!</p>
                                        </CommunityItemContentFooter>
                                    </CommunityItemContent>
                                </CommunityItem>
                            </SwiperSlide>
                        </Swiper>
                    </CommunityRow>
                </div>
                {/* <div className="container">
                    <RenewalFeaturesSection>
                        <RenewalFeaturesRow>
                            <div>
                                <i><img src="/images/icon-tag.png" alt="" width="36" /></i>
                                <h4>Straightforward pricing</h4>
                                <p>All our plans have straightforward pricing with no gimmicks or hidden fees.</p>
                            </div>
                            <div>
                                <i><img src="/images/icon-piggy-bank.png" alt="" width="36" /></i>
                                <h4>Tools to help you save</h4>
                                <p>Save money with our customized energy usage and bill alerts.</p>
                            </div>
                            <div>
                                <i><img src="/images/icon-coins-hand.png" alt="" width="36" /></i>
                                <h4>Free benefits</h4>
                                <p>Every plan comes with our free Pack Benefits, including a $750 emergency fund.</p>
                            </div>
                            <div>
                                <i><img src="/images/icon-hearts.png" alt="" width="36" /></i>
                                <h4>Community impact</h4>
                                <p>With every bill we donate money to help homeless animals throughout Texas.</p>
                            </div>
                        </RenewalFeaturesRow>
                    </RenewalFeaturesSection>
                </div> */}
                {/* <ReviewSection /> */}
                {
                    faqs.length > 0 &&
                    <div className="container">
                        <FaqSection>
                            <SectionHead>
                                <h2>Frequently asked questions</h2>
                                <p>To view our full list of frequently asked questions, please visit <a href="https://help.goodcharlie.com/en/" target="_blank" rel="noreferrer">FAQ</a></p>
                            </SectionHead>
                            <FaqList>
                                {
                                    faqs.map((val: any) => (
                                        val.id !== 546 &&
                                        <FaqListItem isOpen={activeFaqs.indexOf(val.id) >= 0} key={val.id}>
                                            <FaqListItemInner>
                                                <FaqListItemQuestion onClick={() => {
                                                    let [ ...newActiveFaqs ] = activeFaqs;
                                                    const findIdx = newActiveFaqs.indexOf(val.id);
                                                    if (newActiveFaqs.indexOf(val.id) >= 0) {
                                                        newActiveFaqs.splice(findIdx, 1);
                                                    }
                                                    else {
                                                        newActiveFaqs.push(val.id);
                                                    }
                                                    setActiveFaqs(newActiveFaqs);
                                                }}
                                                >{val.label}<i /></FaqListItemQuestion>
                                                <FaqListItemAnswer>{val.descriptions}</FaqListItemAnswer>
                                            </FaqListItemInner>
                                        </FaqListItem>
                                    ))
                                }
                            </FaqList>
                        </FaqSection>
                    </div>
                }
            </div>
            <ProductDetailModal
                data={planDetail}
                show={showPlanDetail}
                handleClose={() => {
                    setShowPlanDetail(false);
                }}
                handleGoEnroll={(val: any) => {
                    redirectToEnrollment(val);
                }}
            />
        </div>
    )
}

export default Product