import { Modal, Spinner } from "react-bootstrap"
import { Button } from './styles/Button.styled';
import { useEffect, useState } from "react";
import { getMaintenanceData } from "../api/api";

export const SGMaintenanceModal= () =>{
    const [showSGMModal, setShowSGMModal] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(true)
    const [modalData, setModalData] = useState({
        header: "",
        body: ``
    })

    function convertToLocalCST(localDate: any) {
        const cstDate = localDate.toLocaleString('en-US', { timeZone: 'America/Chicago' });
        return new Date(cstDate);
    }

    function modalOpenFunc(dateString: any) {
        const inputDate = convertToLocalCST(dateString);
        const targetDate = new Date(2023, 10, 14); // Note: Months are 0-indexed in JavaScript (0 = January, 11 = December)
        if (
            inputDate.getDate() === targetDate.getDate() &&
            inputDate.getMonth() === targetDate.getMonth() &&
            inputDate.getFullYear() === targetDate.getFullYear()
        )
        {
            const hours = inputDate.getHours();
            const minutes = inputDate.getMinutes();        
            if ((hours === 12 && minutes >= 30) || (hours > 12 && hours < 15) || (hours === 15 && minutes <= 30)) {
                return true;
            }
        }
        return false;
    }

    function isCurrentDateTimeInRange(start: any, end: any) {
        const startDate = new Date(start);
        const endDate = new Date(end);
      
        const now = new Date().toLocaleString("en-US", { timeZone: "America/Chicago" });
        const currentDate = new Date(now);
      
        return currentDate >= startDate && currentDate <= endDate;
    }

    useEffect(()=>{
        (async()=>{
            try{
                let res = await getMaintenanceData()
                let _res = res?.picklist[0]?.picklist_entries;
                let finalObj = _res?.find((item:any) => item.key === "plans")
                let _desObject = JSON.parse(finalObj?.descriptions)
                setModalData(_desObject)
                let inRange = isCurrentDateTimeInRange(_desObject?.start, _desObject?.end)       
                setShowSGMModal(inRange);
            }catch(err){
                // console.log("Caught while fetching Maintenance Modal Data",err);
            }
            setLoading(false)
        })()
    },[])

    return(
        <Modal size="lg" show={showSGMModal} centered>
            {
                loading ?
                <div style={{height: "300px", display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <Spinner animation="border" variant="dark" size="sm" className="ms-2" />
                </div> :
                <>
                    <Modal.Header>
                        <Modal.Title><div dangerouslySetInnerHTML={{ __html: modalData.header }} /></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <br/>
                        <div dangerouslySetInnerHTML={{ __html: modalData.body }} />
                    </Modal.Body>
                </>
            }
        </Modal>
    )
}

export default SGMaintenanceModal;
