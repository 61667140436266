import moment from "moment";
import {
  FooterContainer,
  FooterWrapper,
  FooterInner,
  FooterMain,
  FooterBottom,
  FooterCopyright,
  FooterNav,
  FooterInfo,
  FooterPuct,
  FooterContactInfo,
  FooterLinks,
} from "./styles/Footer_new.styled";

const Footer = ({ gcPhone }: { gcPhone: string }) => {
  return (
    <FooterWrapper>
      <FooterContainer>
        <FooterInner>
          <FooterMain>
            <div>
              <a className="FooterGcIcon" href="https://goodcharlie.com/">
                <img
                  alt="GoodCharlie logo glyph"
                  role="img"
                  loading="lazy"
                  decoding="async"
                  data-nimg="fill"
                  style={{
                    position: "absolute",
                    height: "100%",
                    width: "100%",
                    left: 0,
                    top: 0,
                    right: 0,
                    bottom: 0,
                    color: "transparent",
                  }}
                  src="/images/goodcharlie-logo-glyph.svg"
                />
              </a>
              <FooterCopyright>
                ©{moment().format("YYYY")}{" "}
                GoodCharlie &amp; Co d/b/a GoodCharlie Ops
                <br />
                5373 W. Alabama St. Suite 505, Houston, TX 77056
              </FooterCopyright>
            </div>
            <FooterNav>
              <div>
                <h3>About GoodCharlie</h3>
                <ul>
                  <li>
                    <a href="https://plans.goodcharlie.com">Plans</a>
                  </li>
                  <li>
                    <a href="https://goodcharlie.com/pet-perks">Pet Perks</a>
                  </li>
                  <li>
                    <a href="https://goodcharlie.com/impact">Impact</a>
                  </li>
                </ul>
              </div>
              <div>
                <h3>Resources</h3>
                <ul>
                  <li>
                    <a href="https://goodcharlie.com/blog">Blog</a>
                  </li>
                  <li>
                    <a href="https://help.goodcharlie.com/en/">FAQs</a>
                  </li>
                </ul>
              </div>
            </FooterNav>
          </FooterMain>
          <FooterBottom>
            <FooterInfo>
              <FooterPuct>PUCT #10308</FooterPuct>
              <FooterContactInfo>
                <a href="mailto:bark@goodcharlie.com">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                    aria-hidden="true"
                  >
                    <path
                      d="M4.77563 5.12598C3.99226 5.12598 3.38151 5.35402 2.94238 5.81152C2.50326 6.26902 2.2832 6.95958 2.2832 7.88208V15.8691C2.2832 16.7916 2.51795 17.4815 2.98633 17.939C3.45508 18.3965 4.15363 18.6252 5.08325 18.6252H18.2244C19.0074 18.6252 19.6185 18.3965 20.0576 17.939C20.4967 17.4815 20.7161 16.7916 20.7161 15.8691V7.88208C20.7161 6.95958 20.482 6.26902 20.0137 5.81152C19.5449 5.35402 18.8456 5.12598 17.916 5.12598H4.77563ZM5.01807 6.61938H17.9717C18.0436 6.61938 18.1093 6.62701 18.1753 6.63403L12.5378 12.2158C12.3477 12.3988 12.17 12.5346 12.0054 12.6223C11.8407 12.7101 11.6742 12.7542 11.5059 12.7542C11.33 12.7542 11.1593 12.7101 10.9946 12.6223C10.83 12.5346 10.6527 12.3988 10.4622 12.2158L4.82471 6.6333C4.88754 6.62696 4.94977 6.61938 5.01807 6.61938ZM3.80444 7.63892L8.08984 11.8643L3.80591 16.1313C3.79534 16.0484 3.78833 15.9623 3.78833 15.8691V7.87109C3.78833 7.78885 3.7959 7.71321 3.80444 7.63892ZM19.1963 7.64697C19.2049 7.72228 19.2124 7.79855 19.2124 7.88208V15.8801C19.2124 15.9722 19.2057 16.058 19.1948 16.1401L14.9109 11.865L19.1963 7.64697ZM13.8921 12.8677L18.1526 17.1194C18.0934 17.1249 18.0356 17.1318 17.9717 17.1318H5.01807C4.9611 17.1318 4.91009 17.1245 4.85693 17.1201L9.11304 12.8728L9.62793 13.3804C9.94255 13.6879 10.2535 13.9107 10.561 14.0498C10.8685 14.1889 11.1837 14.2585 11.5059 14.2585C11.828 14.2585 12.1407 14.1889 12.4448 14.0498C12.7486 13.9107 13.0574 13.6871 13.3721 13.3796L13.8921 12.8677Z"
                      fill="#5F6368"
                    ></path>
                  </svg>
                  <span>bark@goodcharlie.com</span>
                </a>
                <a href={`tel:${gcPhone ? gcPhone : "1-800-205-5230"}`}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                    aria-hidden="true"
                  >
                    <path
                      d="M9.02075 1.88143C8.69162 1.85749 8.35438 1.89113 8.02368 1.98885L6.84009 2.33944C5.57886 2.71244 4.59341 3.71313 4.26001 4.97616C3.51972 7.78503 4.36658 11.1097 6.57056 14.8521C8.77143 18.5894 11.2793 20.9646 14.1116 21.7398C15.3871 22.0889 16.7626 21.7555 17.7288 20.858L18.6291 20.023C19.6367 19.0876 19.7869 17.5295 18.9729 16.4224L17.6721 14.6558C16.9748 13.7088 15.7457 13.3056 14.6155 13.6451L12.5803 14.2545C12.4949 14.2794 12.2905 14.1631 12.1125 14.0094L12.1116 14.0084C11.7947 13.7329 11.3557 13.176 10.886 12.3795V12.3785C10.3824 11.5231 10.1675 10.9637 10.0813 10.6334C9.99502 10.3029 10.016 10.2627 10.0286 10.151C10.0351 10.0952 10.0616 10.0436 10.1067 10.0025L10.1077 10.0016L11.6252 8.61385C12.4947 7.81903 12.7572 6.54885 12.2668 5.48007L11.3518 3.48397V3.483C10.921 2.54395 10.0079 1.95325 9.02075 1.88143ZM8.91235 3.37753C9.37145 3.41183 9.79421 3.68721 9.98755 4.10897L10.9036 6.10507C11.1252 6.58828 11.0141 7.14123 10.6135 7.50741L9.09595 8.89413C8.78484 9.17779 8.58589 9.56454 8.53833 9.98202V9.983C8.52594 10.0928 8.49666 10.501 8.63013 11.0123C8.76359 11.5235 9.03915 12.1998 9.59302 13.1402H9.59399C10.1108 14.0167 10.5992 14.6826 11.1282 15.1422H11.1291C11.3009 15.2909 12.0209 15.9809 13.0002 15.6949L13.0032 15.6939L15.0471 15.0816C15.5779 14.9222 16.1454 15.1126 16.4641 15.5455L17.7649 17.3121C18.1332 17.8139 18.0689 18.4961 17.6086 18.9234L16.7083 19.7594C16.1324 20.2945 15.2773 20.5034 14.5071 20.2926C12.2174 19.6658 9.95165 17.6372 7.86352 14.0914C5.7725 10.5408 5.11849 7.6041 5.7102 5.35897C5.9058 4.618 6.50509 4.00291 7.26587 3.77792L8.44849 3.42733C8.60254 3.38181 8.75932 3.36609 8.91235 3.37753Z"
                      fill="#5F6368"
                    ></path>
                  </svg>
                  <span>{gcPhone ? gcPhone : "1-800-205-5230"}</span>
                </a>
              </FooterContactInfo>
            </FooterInfo>
            <FooterLinks>
              <div>
                <a
                  href="https://instagram.com/mygoodcharlie"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="19"
                    viewBox="0 0 18 19"
                    fill="none"
                    aria-hidden="true"
                  >
                    <path
                      d="M5 0.455078C2.239 0.455078 0 2.69408 0 5.45508V13.4551C0 16.2161 2.239 18.4551 5 18.4551H13C15.761 18.4551 18 16.2161 18 13.4551V5.45508C18 2.69408 15.761 0.455078 13 0.455078H5ZM15 2.45508C15.552 2.45508 16 2.90308 16 3.45508C16 4.00708 15.552 4.45508 15 4.45508C14.448 4.45508 14 4.00708 14 3.45508C14 2.90308 14.448 2.45508 15 2.45508ZM9 4.45508C11.761 4.45508 14 6.69408 14 9.45508C14 12.2161 11.761 14.4551 9 14.4551C6.239 14.4551 4 12.2161 4 9.45508C4 6.69408 6.239 4.45508 9 4.45508ZM9 6.45508C8.20435 6.45508 7.44129 6.77115 6.87868 7.33376C6.31607 7.89637 6 8.65943 6 9.45508C6 10.2507 6.31607 11.0138 6.87868 11.5764C7.44129 12.139 8.20435 12.4551 9 12.4551C9.79565 12.4551 10.5587 12.139 11.1213 11.5764C11.6839 11.0138 12 10.2507 12 9.45508C12 8.65943 11.6839 7.89637 11.1213 7.33376C10.5587 6.77115 9.79565 6.45508 9 6.45508Z"
                      fill="#202124"
                    ></path>
                  </svg>
                  <span className="sr-only">Instagram</span>
                </a>
                <a
                  href="https://facebook.com/mygoodcharlie"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    aria-hidden="true"
                  >
                    <path
                      d="M9.6 0.353516C4.2984 0.353516 0 4.65192 0 9.95352C0 14.7663 3.5456 18.7407 8.1648 19.4351V12.4975H5.7896V9.97432H8.1648V8.29512C8.1648 5.51512 9.5192 4.29512 11.8296 4.29512C12.936 4.29512 13.5216 4.37752 13.7984 4.41432V6.61672H12.2224C11.2416 6.61672 10.8992 7.54712 10.8992 8.59512V9.97432H13.7736L13.384 12.4975H10.8992V19.4551C15.5848 18.8199 19.2 14.8135 19.2 9.95352C19.2 4.65192 14.9016 0.353516 9.6 0.353516Z"
                      fill="#202124"
                    ></path>
                  </svg>
                  <span className="sr-only">Facebook</span>
                </a>
                <a
                  href="https://www.tiktok.com/@mygoodcharlie"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                    aria-hidden="true"
                  >
                    <path
                      d="M17.9592 0.294922H2.59922C1.27586 0.294922 0.199219 1.37156 0.199219 2.69492V18.0549C0.199219 19.3783 1.27586 20.4549 2.59922 20.4549H17.9592C19.2826 20.4549 20.3592 19.3783 20.3592 18.0549V2.69492C20.3592 1.37156 19.2826 0.294922 17.9592 0.294922ZM16.0421 9.08996C15.9331 9.10004 15.8227 9.10676 15.7109 9.10676C14.4519 9.10676 13.3455 8.45924 12.7018 7.48052C12.7018 10.048 12.7018 12.9693 12.7018 13.0183C12.7018 15.2786 10.8691 17.1112 8.60882 17.1112C6.3485 17.1112 4.51586 15.2786 4.51586 13.0183C4.51586 10.758 6.3485 8.92532 8.60882 8.92532C8.69426 8.92532 8.77778 8.933 8.86178 8.93828V10.9552C8.77778 10.9452 8.69522 10.9298 8.60882 10.9298C7.4549 10.9298 6.51986 11.8648 6.51986 13.0188C6.51986 14.1727 7.4549 15.1077 8.60882 15.1077C9.76274 15.1077 10.7818 14.1986 10.7818 13.0447C10.7818 12.9991 10.8019 3.63956 10.8019 3.63956H12.7296C12.9111 5.36324 14.3026 6.72356 16.0421 6.84836V9.08996Z"
                      fill="#202124"
                    ></path>
                  </svg>
                  <span className="sr-only">TikTok</span>
                </a>
                <a
                  href="https://www.linkedin.com/company/goodcharlie"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="23"
                    height="23"
                    viewBox="0 0 23 23"
                    fill="none"
                    aria-hidden="true"
                  >
                    <path
                      d="M11.3994 0.375C5.31201 0.375 0.359375 5.32716 0.359375 11.415C0.359375 17.5028 5.31201 22.455 11.3994 22.455C17.4867 22.455 22.4394 17.5028 22.4394 11.415C22.4394 5.32716 17.4867 0.375 11.3994 0.375ZM8.03937 16.215H6.11937V9.015H8.03937V16.215ZM7.07937 7.575C6.54897 7.575 6.11937 7.1454 6.11937 6.615C6.11937 6.0846 6.54897 5.655 7.07937 5.655C7.60978 5.655 8.03937 6.0846 8.03937 6.615C8.03937 7.1454 7.60978 7.575 7.07937 7.575ZM17.1594 16.215H15.2394V13.815V12.615C15.2394 11.691 14.4834 10.935 13.5594 10.935C12.6354 10.935 11.8794 11.691 11.8794 12.615V16.215H9.95937V9.015H11.8794V9.88668C12.44 9.34812 13.2003 9.015 14.0394 9.015C15.7626 9.015 17.1594 10.4118 17.1594 12.135V16.215Z"
                      fill="#202124"
                    ></path>
                  </svg>
                  <span className="sr-only">LinkedIn</span>
                </a>
              </div>
              <div>
                <a
                  href="https://goodcharlie.com/GoodCharlie_Privacy_Policy.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy
                </a>
              </div>
            </FooterLinks>
          </FooterBottom>
        </FooterInner>
      </FooterContainer>
    </FooterWrapper>
  );
};

export default Footer;
