import { useEffect, useState } from "react";
import { ReviewRating, Starts } from "./styles/ReviewRating.styled";
import { fetchGlobalSettings } from "../api/api";

export const GoogleReviewRating = () => {
    const [ rating, setRating ] = useState(0);

    useEffect(() => {
        // window.onload = () => {
        //     const map = new google.maps.Map(document.getElementById('map')!, {
        //         center: { lat: -34.397, lng: 150.644 },
        //         zoom: 15,
        //     });
        
        //     const placeService = new google.maps.places.PlacesService(map);
        //     const request = {
        //         placeId: "ChIJp-aOLxbBQIYR41lp2t47iiM",
        //         fields: ["name", "formatted_address", "place_id", "geometry", "rating", "reviews"],
        //     };
        //     placeService.getDetails(request, (place, status) => {
        //         if (status === google.maps.places.PlacesServiceStatus.OK) {
        //             setRating(place.rating!);
        //         }
        //     });
        // }

        fetchGlobalSettings().then((res)=>{
            if (res && res.status === 1) {
                setRating(res.response.google_rating)
            }
        })
    }, []);

    return (
        <>
            <div id="map" />
            {
                rating !== 0 &&
                <ReviewRating>
                    <img src="/images/icon-google.png" className="google-icon" alt="" />
                    <div>
                        <Starts>
                            <img src="/images/StarFilled.png" alt="" width="20" height="20" />
                            <img src="/images/StarFilled.png" alt="" width="20" height="20" />
                            <img src="/images/StarFilled.png" alt="" width="20" height="20" />
                            <img src="/images/StarFilled.png" alt="" width="20" height="20" />
                            <img src="/images/StarFilled.png" alt="" width="20" height="20" />
                        </Starts>
                        <p>{rating} rating on</p>
                        <p>Google</p>
                    </div>
                </ReviewRating>
            }
        </>
    )
}

export const PucReviewRating = () => {

    return (
        <>
            {
                <ReviewRating>
                    <img src="/images/icon-puc.png" className="puc-icon" alt=""  />
                    <div>
                        <Starts>
                            <img src="/images/StarFilled.png" alt="" width="20" height="20" />
                            <img src="/images/StarFilled.png" alt="" width="20" height="20" />
                            <img src="/images/StarFilled.png" alt="" width="20" height="20" />
                            <img src="/images/StarFilled.png" alt="" width="20" height="20" />
                            <img src="/images/StarFilled.png" alt="" width="20" height="20" />
                        </Starts>
                        <p>5.0 rating by</p>
                        <p>PUC Texas</p>
                    </div>
                    
                </ReviewRating>
            }
        </>
    )
}
