import styled from 'styled-components';
import { Button } from './Button.styled';

export const ZipcodeSection = styled.div`
    text-align: center;
    padding-top: 30px;
    padding-bottom: 60px;

    h1 {
        font-weight: 700;
        font-size: 48px;
    }

    p {
        font-weight: 600;
        font-size: 20px;
        color: rgba(33, 33, 33, 0.52);
        margin-top: 24px;
        margin-bottom: -4px;
    }

    @media (max-width: 768px) {
        h1 {
            font-size: 48px;
        }

        p {
            font-size: 20px;
        }
    }
    
    @media (max-width: 460px) {
        padding-top: 60px;
        padding-bottom: 100px;

        h1 {
            font-size: 36px;
        }

        p {
            font-size: 16px;
            margin-top: 20px;
        }
    }
`

export const ZipcodeCol = styled.div`
    @media (max-width: 460px) {
        width: 100%;
        position: relative;
        
        input.form-control {
            margin: 0 auto;
        }

        #utility-dropdown {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: -140px;
            z-index: 1;
        }

        #utility-dropdown .dropdown-toggle {
            box-sizing: border-box;
        }
    }
`

export const ZipcodeRow = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 20px;

    .form-group {
        margin-top: 0;
    }

    input.form-control {
        padding-top: 12px;
        padding-bottom: 12px;
        background-color: #fff;
    }

    ${Button} {
        padding: 12px 24px;
        margin-left: 24px;
    }

    @media (max-width: 460px) {
        input.form-control {
            width: 206px;
        }
        flex-direction: column;
        margin-bottom: 32px;
        align-items: center;

        ${Button} {
            margin-left: 0;
        }
    }
`

export const PlanTabGroupRow = styled.div`
    display: flex;
    justify-content: center;
`

export const PlanTabGroup = styled.div`
    background-color: #F9FAFB;
    border: 1px solid #F2F4F7;
    padding: 4px;
    border-radius: 40px;

    span {
        display: inline-flex;
        align-items: center;
        font-size: 16px;
        font-weight: 700;
        padding: 10px 18px;
        color: #667085;
        cursor: pointer;
    }

    span.active {
        background-color: #fff;
        color: #344054;
        border-radius: 40px;
        box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
    }

    span.active::after {
        content: ' ';
        display: inline-block;
        width: 15px;
        height: 15px;
        margin-left: 4px;
        mask: url('/images/Check.svg') 0 0 no-repeat;
        mask-size: cover;
        background-color: ${({ theme }) => theme.colors.primary};
    }

    @media (max-width: 540px) {
        span {
            font-size: 14px;
        }
    }
`

export const RenewalFeaturesSection = styled.div`
    margin: 40px 0 100px;
    border-radius: 24px;
    border: 1px solid #f2f2f2;
    background-color: #fff;
`

export const RenewalFeaturesRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    border-radius: 24px;
    padding: 40px 0;
    row-gap: 50px;

    /* &:last-child {
        border-bottom: 0 none;
    } */

    & > div {
        display: flex;
        text-align: center;
        flex: 1;
        flex-direction: column;
        align-items: center;
        border-right: 1px solid #f2f2f2;
    }

    & > div:last-child {
        margin-left: -1px;
        border-right: 0 none;
    }

    p {
        font-size: 14px;
        max-width: 200px;
        padding: 0 8px;
        margin: 0;
    }

    @media (max-width: 768px) {
        & > div {
            flex: 0 0 50%;
        }
    }
`

export const ReviewsSectionHeading = styled.div`
    h2 {
        margin: 0 0 16px;
        font-size: 2.125rem;
        font-weight: 700;
        text-align: center;
    }

    p {
        text-align: center;
    }
`

export const ReviewsWrapper = styled.div`
    padding: 3.5rem 0px 0px 8.68vw;
`

export const ReviewsContainer = styled.div`
    @keyframes slide{from{-webkit-transform:translateX(0%);-ms-transform:translateX(0%);transform:translateX(0%);}to{-webkit-transform:translateX(-100%);-ms-transform:translateX(-100%);transform:translateX(-100%);}}

    display: inline-flex;
    width: calc(184rem);
    animation: 56s linear 0s infinite normal both running slide;

    &:hover {
        animation-play-state: paused;
    }
    
    @media (max-width: 767.98px) {
        width: calc(136rem);
    }
`

export const ReviewBox = styled.div`
    padding: 2.8125rem 0.5rem 3.5rem;
    min-width: 23rem;
    text-align: left;
    box-sizing: border-box;

    @media (max-width: 767.98px) {
        padding-top: 2.125rem;
        min-width: 17rem;
    }
`

export const ReviewInner = styled.div`
    background: #fff;
    border: 3px solid #ffe9ee;
    box-shadow: rgb(224 224 224 / 20%) 0px 9px 30px;
    border-radius: 24px;
    padding: 0px 2.625rem 3.625rem 3.375rem;
    display: flex;
    flex-direction: column;
    min-height: 27.5rem;
    height: 27.5rem;
    max-height: 100%;
    transition: transform 0.25s ease 0s;
    box-sizing: border-box;

    &:hover {
        transform: scale(1.015);
    }

    @media (min-width: 768px) and (max-width: 1199.98px) {
        min-height: 25rem;
        height: 25rem;
    }

    @media (min-width: 768px) and (max-width: 991.98px) {
        padding: 0px 1.625rem 3.625rem 2.375rem;
    }

    @media (max-width: 767.98px) {
        padding: 0px 1.875rem 2.625rem 2.5rem;
        min-height: 22rem;
        height: 22rem;
    }
`

export const ReviewThumbnail = styled.div`
    width: 89px;
    height: 89px;
    border-radius: 50%;
    position: relative;
    margin: -2.8125rem 0px 1.5rem;
    flex-shrink: 0;

    span {
        box-sizing: border-box;
        display: block;
        overflow: hidden;
        width: initial;
        height: initial;
        background: none;
        opacity: 1;
        border: 0;
        margin: 0;
        padding: 0;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
    img {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        box-sizing: border-box;
        padding: 0;
        border: none;
        margin: auto;
        display: block;
        width: 0;
        height: 0;
        min-width: 100%;
        max-width: 100%;
        min-height: 100%;
        max-height: 100%;
        object-fit: cover;
        object-position: center;
    }
    
    @media (max-width: 767.98px) {
        width: 66px;
        height: 66px;
        margin: -2.125rem 0px 1.125rem;
    }
`

export const Reviewer = styled.div`
    h5 {
        font-weight: 700;
        font-size: 1.25rem;
        line-height: 28px;
        letter-spacing: -0.28px;
        color: #3a3a3a;
        margin-bottom: 0.25rem;
        margin-top: 0;
    }

    p {
        font-size: 0.875rem;
        line-height: 20px;
        color: #0bcebb;
        margin-bottom: 0;
    }

    p a {
        text-decoration: none;
        color: #0bcebb;
    }

    @media (min-width: 768px) and (max-width: 1199.98px) {
        h5 {
            font-size: 1.125rem;
            line-height: 22px;
        }
    }

    @media (max-width: 767.98px) {
        h5 {
            font-size: 1rem;
            line-height: 21px;
            letter-spacing: -0.2px;
        }
    }

    @media (max-width: 1199.98px) {
        p {
            font-size: 0.75rem;
        }
    }
`

export const ReviewRank = styled.div`
    margin: 1.25rem 0px 1.375rem;

    @media (max-width: 767.98px) {
        margin: 1rem 0px 1.125rem;
    }
`

export const ReviewComment = styled.div`
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 30px;
    letter-spacing: -0.35px;
    color: #3a3a3a;

    @media (min-width: 768px) and (max-width: 1199.98px) {
        font-size: 1.125rem;
        line-height: 26px;
    }

    @media (max-width: 767.98px) {
        font-size: 1rem;
        line-height: 22px;
        letter-spacing: -0.26px;
    }
`

export const RenewalPlanList = styled.div`
    display: flex;
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    padding: 24px 20px;
    justify-content: space-between;
    flex-wrap: wrap;
    /* row-gap: 20px; */
    box-sizing: border-box;
    position: relative;
`

interface RenewalPlanItemProps {
    recommended: boolean;
}

export const RenewalPlanItem = styled.div<RenewalPlanItemProps>`
    /* width: 31%; */
    padding: 34px 4px 4px;
    border-radius: 28px;
    position: relative;
    background-color: ${({ recommended, theme }) => recommended ? theme.colors.secondary : 'transparent'};

    &::after {
        display: ${({ recommended }) => recommended ? 'inline-block' : 'none'};
        content: 'Recommended';
        position: absolute;
        top: 5px;
        left: 50%;
        transform: translateX(-50%);
        font-weight: 700;
        color: #fff;
        font-size: 13px;
        background: url('/static/images/recommend-star.png') 0 0 no-repeat;
        background-size: 11px;
        padding-left: 16px;
    }

    /* @media (max-width: 1125px) {
        width: 48%;
    } */

    /* @media (max-width: 540px) {
        width: 100%;
    } */
`

interface RenewalPlanItemInnerProps{
    isPopular?: boolean;
}

export const RenewalPlanItemInner = styled.div<RenewalPlanItemInnerProps>`
    transition: all .3s;
    background-color: #fff;
    border: 1px solid #EAECF0;
    border-radius: 22px;
    position: relative;
    box-shadow: 0px 9px 15px rgba(224, 224, 224, 0.4);

    ::before {
        content: ' ';
        display: ${({ isPopular }) => isPopular ? 'inline-block' : 'none'};
        width: 125px;
        height: 63px;
        background: url('/images/popular-arrow.png') 0 0 no-repeat;
        background-size: cover;
        position: absolute;
        top: -37px;
        right: 71px;
    }

    &:hover {
        transform: scale(1.02);
        border-color: #FF5A6A;
    }
`

export const RenewalPlanName = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 24px 8px;
    height: 65px;

    b {
        font-size: 15px;
    }
`

export const RenewalPlanPrice = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 24px 32px;

    & > div {
        display: flex;
        align-items: flex-start;
    }

    & > span {
        color: rgba(33, 33, 33, 0.52);
    }

    b > span {
        font-size: 52px;
        line-height: 1.2;
    }

    i {
        font-size: 26px;
        font-weight: 700;
        font-style: normal;
    }

    span {
        font-size: 15px;
        font-weight: 700;
    }
`

export const RenewalPlanAction = styled.div`
    display: flex;
    gap: 12px;
    padding: 0 20px;

    ${Button} {
        flex: 1;
        padding-left: 0;
        padding-right: 0;
    }
`

export const RenewalPlanInfo = styled.div`
    margin-top: 24px;
    padding: 16px 24px 24px;
    border-top: 1px solid #EAECF0;
`

interface RenewalPlanLabelProps {
    type: string;
}

export const RenewalPlanLabel = styled.span<RenewalPlanLabelProps>`
    font-size: 14px;
    font-weight: 700;
    color: ${({ type, theme }) => type === 'red' ? theme.colors.primary : type === 'blue' ? '#35C0FC' : '#0BCEBB' };
    background-color: ${({ type }) => type === 'red' ? 'rgba(255, 247, 248, 0.6)' : type === 'blue' ? 'rgba(215, 242, 254, 0.2)' : 'rgba(206, 245, 241, 0.2)' };
    border-radius: 20px;
    padding: 4px 12px;
    display: inline-block;
`

export const RenewalPlanRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 12px 0;

    label {
        display: inline-flex;
        font-weight: normal;
        align-items: center;
        color: rgba(58, 58, 58, 0.7);

        img {
            margin-right: 4px;
        }
    }

    span {
        text-align: right;
        font-weight: 600;
    }

    .renewal-tooltip-theme {
        width: 160px;
        line-height: 1.5;
        font-size: 13px;
        border-radius: 8px !important;
        opacity: 1 !important;
        color: #333;
        background-color: rgba(255, 255, 255, .95);
        box-shadow: 0 4px 8px rgba(0, 0, 0, .1);
        text-align: left;
    }

    .renewal-tooltip-icon {
        display: inline-block;
        width: 16px;
        height: 16px;
        mask-image: url('../images/questionmark.svg');
        mask-size: cover;
        background-color: #98A2B3;
        margin-left: 4px;
        flex-shrink: 0;
    }
`

export const RenewalPlanDetailSection = styled.div`
    margin: 20px 0;
    border: 1px solid #FFDEE1;
    border-radius: 24px;
    background-color: #FFF9F9;
    padding: 12px 20px;
`

export const RenewalPlanDetailRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0;
    color: #475467;
    font-size: 13px;

    label {
        font-weight: 700;
    }
`

export const RenewalPlanDetailFooter = styled.div`
    border-top: 1px solid rgba(33, 33, 33, 0.1);
    padding-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    display: flex;

    ${Button} {
        background-color: #fff;
    }
`

export const PlanDocLink = styled.a`
    display: inline-flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.secondary};
    text-decoration: none;
    font-size: 16px;
    padding: 8px 0;

    ::before {
        content: ' ';
        display: block;
        width: 16px;
        height: 16px;
        mask-image: url('/images/LinkExternal.svg');
        background-color: ${({ theme }) => theme.colors.secondary};
        mask-size: cover;
        margin-right: 8px;
    }
`

export const RenewalPlanDetailLinks = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex: 1;

    ${PlanDocLink} {
        margin-right: 12px;
        color: #FF5B6A;
        font-size: 14px;

        &::before {
            width: 14px;
            height: 14px;
            background-color: #FF5B6A;
        }

        &::after {
            display: inline-block;
            width: 1px;
            height: 12px;
            content: ' ';
            background-color: rgba(33, 33, 33, 0.1);
            margin-left: 12px;
        }

        &:last-child::after {
            display: none;
        }
    }
`

export const RenewalPlanDetailContent = styled.div`
    display: flex;
    flex-direction: row-reverse;
    text-align: left;
    gap: 40px;
    padding: 0 16px;
    
    @media (max-width: 540px) {
        flex-direction: column-reverse;
        padding: 0;
        gap: 0;
    }
`

export const RenewalPlanDetailInfo = styled.div`
    flex: 1;

    h4 {
        font-size: 20px;
        font-weight: 700;
    }
`

export const RenewalPlanNameRow = styled.div`
    display: flex;
    align-items: center;
    gap: 24px;

    h4 {
        margin: 0;
        font-size: 20px;
        font-weight: 700;
    }

    @media (max-width: 540px) {
        justify-content: space-between;
    }
`

export const RenewalPlanDetailFacts = styled.div`
    width: 40%;

    ${RenewalPlanPrice} {
        /* margin-top: 60px; */
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;

        span {
            margin-left: 20px;
            font-size: 12px;
            line-height: 18px;
            font-weight: 600;
            font-style: italic;
        }

        b > span {
            font-size: 44px;
            font-style: normal;
            font-weight: 700;
            margin-left: 0;
            line-height: inherit;
        }
    }

    ${RenewalPlanInfo} {
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
        border-top: 0 none;
        margin-top: 0;
    }

    @media (max-width: 540px) {
        width: 100%;

        ${RenewalPlanPrice} {
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 20px;

            span {
                margin-left: 0;
            }
        }
    }
`

export const RenewalPlanDetailPriceTable = styled.div`
    font-size: 14px;
    margin-bottom: 40px;
    margin-top: 42px;
`

export const RenewalPlanDetailPriceThead = styled.div`
    display: flex;

    span {
        display: inline-block;
        flex: 1;
        margin-bottom: 20px;
    }
`

export const RenewalPlanDetailPriceTbody = styled.div`
    background-color: rgba(243, 243, 243, 0.5);
    border-radius: 12px;

    & > div {
        display: flex;
        border-bottom: 1px solid #f2f2f2;
    }

    & > div:last-child {
        border-bottom: 0 none;
    }
    
    span {
        display: inline-block;
        flex: 1;
        padding: 12px 20px;
        box-sizing: border-box;
        border-right: 1px solid #f2f2f2;
    }

    span:last-child {
        border-right: 0 none;
    }
`

export const RenewalPlanDetailDocs = styled.div`
    display: flex;
    flex-direction: column;

    ${PlanDocLink} {
        color: #FF5B6A;

        &::before {
            background-color: #FF5B6A;
        }
    }
`

export const RenewalPlanEbillLabel = styled.div`
    display: inline-flex;
    align-items: center;
    background-color: rgba(33, 33, 33, 0.04);
    border-radius: 8px;
    padding: 12px 16px;
    font-size: 12px;
    margin-top: 12px;
    margin-bottom: 24px;
    font-weight: 700;

    img {
        margin-right: 8px;
    }
`

export const RenewalFreePackLabel = styled.div`
    display: inline-flex;
    align-items: center;
    background-color: #FFF7F8;
    border-radius: 6px;
    padding: 4px 12px;
    font-size: 14px;
    color: #FF5A6A;
    margin-bottom: 24px;
    font-weight: 600;

    img {
        margin-right: 8px;
    }
`

export const RenewalPlanDetailBottom = styled.div`
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 24px;
    margin: 16px;

    ${RenewalFeaturesRow} {
        background-color: rgba(243, 243, 243, 0.2);

        h4 {
            font-size: 20px;
            font-weight: 700;
            margin: 12px 0 16px;
        }
    }
    
    @media (max-width: 540px) {
        /* flex-direction: column;
        margin-left: 0;
        margin-right: 0; */
        display: none;
    }
`

export const NoDataRow = styled.div`
    height: 580px;
    text-align: center;
    color: #999;
`

export const PlanError = styled.div`
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    padding-bottom: 100px;
`

export const ModalBody = styled.div`
    text-align: center;
`

export const ModalTitle = styled.h2`
    color: #3a3a3a;
    font-size: 20px;
    margin-top: 16px;
    padding: 0 24px;
    text-align: center;
`

export const ModalClose = styled.button`
    display: inline-block;
    padding: 8px;
    background-color: #F3F3F3;
    border-radius: 50%;
    cursor: pointer;
    border: 0 none;
    position: absolute;
    top: 18px;
    right: 16px;
    font-size: 0;

    ::after {
        display: inline-block;
        width: 20px;
        height: 20px;
        content: ' ';
        background: url('/images/X.svg') 0 0 no-repeat;
        background-size: cover;
    }
`

export const FaqSection = styled.div`
    margin: 50px 0;
`

export const SectionHead = styled.div`
    text-align: center;
    margin-bottom: 50px;

    h2 {
        font-size: 34px;
        font-weight: 700;
    }

    p {
        color: rgba(33, 33, 33, 0.52);

        a {
            color: #35C0FC;
        }
    }
`

export const FaqList = styled.div`
    max-width: 768px;
    margin: 0 auto;
`

interface FaqListItemProps {
    isOpen: boolean;
}

export const FaqListItemQuestion = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    font-weight: 700;
    cursor: pointer;
    padding: 4px 0 8px;

    i {
        display: inline-block;
        width: 24px;
        height: 24px;
        background-position: 0 0;
        background-repeat: no-repeat;
        background-size: cover;
    }
`

export const FaqListItemAnswer = styled.div`
    color: rgba(40, 40, 40, 0.7);
    max-height: 0;
    overflow: hidden;
    white-space: pre-line;
    /* transition: all .3s; */
`

export const FaqListItemInner = styled.div`
    border-radius: 24px;
    padding: 24px;
`

export const FaqListItem = styled.div<FaqListItemProps>`
    border-bottom: 1px solid #eee;
    padding: 12px 0;

    ${FaqListItemQuestion} {
        i {
            flex-shrink: 0;
            background-image: ${({ isOpen }) => isOpen ? "url('/images/minus-circle.png')" : "url('/images/plus-circle.png')"};
            /* background-color: ${({ theme, isOpen }) => isOpen ? theme.colors.primary : 'rgba(33, 33, 33, 0.52)'}; */
        }
    }

    ${FaqListItemInner} {
        background-color: ${({ isOpen }) => isOpen ? '#fff' : 'transparent' };
        border: ${({ isOpen }) => isOpen ? '1px solid #eee' : '0 none' };
        box-shadow: ${({ isOpen }) => isOpen ? '0px 12px 18px -4px rgba(16, 24, 40, 0.05)' : 'none' };
    }

    ${FaqListItemAnswer} {
        max-height: ${({ isOpen }) => isOpen ? '9999px' : 0 };
    }
`

interface SwiperBtnProps {
    various: string;
}

export const SwiperBtn = styled.button<SwiperBtnProps>`
    background-color: rgba(255, 233, 238, 1);
	position: absolute;
	top: 50%;
	left: ${({ various }) => various === 'next' ? 'auto' : 0};
	right: ${({ various }) => various === 'next' ? 0 : 'auto'};
	z-index: 100;
	border-radius: 50%;
	font-size: 0;
	padding: 10px;
	transform: translateY(-50%);
    border: 0 none;
    cursor: pointer;

    i {
        display: inline-block;
        width: 24px;
        height: 24px;
        content: ' ';
        mask: url('/images/ChevronRight.svg') 0 0 no-repeat;
        mask-size: cover;
        transform: ${({ various }) => various === 'next' ? 'none' : 'rotate(180deg)'};
        background-color: #FF5A6A;
    }
`

export const CommunityRow = styled.div`
    margin-top: 100px;

    .swiper-pagination {
        margin-top: 24px;
    }

    @media (min-width: 992px) {
        .swiper-pagination {
            display: none;
        }
    }
    
    @media (max-width: 540px) {
        margin-top: 44px;
    }
`

export const CommunityItem = styled.div`
    height: 524px;
    border-radius: 24px;
    box-shadow: 0px 9px 30px rgba(224, 224, 224, 0.2);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
`

export const CommunityItemContent = styled.div`
    position: absolute;
    border-radius: 23px;
    background-color: #fff;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 16px;
    min-height: 205px;
    
    p {
        font-size: 16px;
        line-height: 24px;
    }
`

export const CommunityItemContentHeader = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
    margin-bottom: 12px;
`

export const CommunityItemContentFooter = styled.div`
    h6 {
        font-size: 16px;
        font-weight: 700;
        margin: 0;
    }

    p {
        font-size: 14px;
        font-weight: 600;
        margin: 4px 0 0;
        color: #0BCEBB;
    }
`

export const NewFeatureSection = styled.div`
    /* background: url('/images/bg.png') bottom left no-repeat; */
    /* background-size: cover; */
    padding: 80px 0 40px;
    
    @media (max-width: 540px) {
        padding-top: 40px;
    }
`

export const NewFeatureSectionInner = styled.div`
    height: 650px;
    background: url('/images/feature-bg.png') center center no-repeat;
    background-size: auto 80%;
    position: relative;

    div {
        position: absolute;
        background-color: #fff;
        padding: 24px;
        width: 100%;
        max-width: 395px;
        border: 1px solid rgba(58, 58, 58, 0.1);
        box-shadow: 0px 9px 30px rgba(224, 224, 224, 0.2);
        border-radius: 32px;

        h4 {
            font-size: 20px;
            font-weight: 700;
            margin: 16px 0 12px;
        }

        p {
            font-size: 18px;
            margin: 0;
        }
    }

    div:nth-child(1) {
        top: 10%;
        right: 5%;
    }
    div:nth-child(2) {
        top: 10%;
        left: 5%;
    }
    div:nth-child(3) {
        bottom: 10%;
        right: 5%;
    }
    div:nth-child(4) {
        bottom: 10%;
        left: 5%;
    }

    @media (max-width: 1400px) {
        div:nth-child(1) {
            top: 5%;
        }
        div:nth-child(2) {
            top: 5%;
        }
        div:nth-child(3) {
            bottom: 5%;
        }
        div:nth-child(4) {
            bottom: 5%;
        }
    }

    @media (max-width: 991px) {
        display: flex;
        gap: 20px;
        flex-direction: column;
        align-items: center;
        height: auto;
        background: none;
        margin-top: 40px;

        div {
            position: static;
        }
    }

    @media (max-width: 540px) {
        div {
            h4 {
                font-size: 16px;
            }

            p {
                font-size: 14px;
            }
        }
    }
`

export const MiddleSection = styled.div`
    padding-top: 60px;
    // background: url('/images/bg.jpg') center bottom no-repeat;
    background-size: cover;
    margin-bottom: 60px;
`